import { BoardgamePriceCategorySlideover } from '#components';
import type { Boardgame, BoardgameList } from '~/interfaces/Boardgame';
import type { CategoryBoardgame } from '~/interfaces/Category';

interface LoadBoardgamesFilter {
  categories: number[] | number;
  id: string;
  name: string;
  page: number;
  mechanics: number[] | number;
  designers: number[] | number;
  publishers: number[] | number;
  artists: number[] | number;
}

export const useBoardgame = (slug?: string) => {
  const slideover = useSlideover();
  const client = useSanctumClient();
  const toast = useToast();

  const boardgames = useState<BoardgameList>(
    `boardgames:${slug}`,
    () => ({} as BoardgameList)
  );
  const boardgame = useState(`boardgame${slug}`, () => ({} as Boardgame));
  const loading = ref(false);

  const loadBoardgames = async (query: LoadBoardgamesFilter) => {
    loading.value = true;
    try {
      const { data, error } = await useAsyncData(`boardgames${slug}`, () =>
        client('/api/boardgames', { params: query })
      );
      loading.value = false;

      if (error.value) {
        toast.add({ title: error.value.data.message, color: 'red' });
        return;
      }

      boardgames.value = data.value as BoardgameList;
    } finally {
      loading.value = false;
    }
  };

  const getBoardgameById = async (id: string) => {
    loading.value = true;
    try {
      const { data, error } = await useAsyncData(`boardgame:${id}`, () =>
        client(`/api/boardgames/${id}`)
      );

      if (error.value) {
        toast.add({ title: error.value.data.message, color: 'red' });
        return;
      }

      boardgame.value = data.value as Boardgame;
    } finally {
      loading.value = false;
    }
  };

  const findBoardgameByName = async (query: string) => {
    loading.value = true;
    try {
      const { data, error } = await useAsyncData(`boardgames:${query}`, () =>
        client(`/api/boardgames/search/${query}`)
      );
      loading.value = false;

      if (error.value) {
        return [];
      }

      return data.value as Boardgame[];
    } finally {
      loading.value = false;
    }
  };

  const openPriceCategory = (boardgame: CategoryBoardgame) => {
    slideover.open(BoardgamePriceCategorySlideover, {
      onclose: slideover.close,
      boardgame,
    });
  };

  return {
    boardgames,
    loading,
    loadBoardgames,
    openPriceCategory,
    boardgame,
    getBoardgameById,
    findBoardgameByName,
  };
};
